import React from 'react';

import withSettings from '../../../utils/withSettings';

const Cell = ({ data, creatable, settings }) => {
  return (
    <div style={{ color: !creatable ? settings.colors.danger : '' }}>
      {data}
    </div>
  );
};

export default withSettings(Cell);
