export default {
  root: {
    width: '100%',
    backgroundColor: '#FAFAFA'
  },
  radioContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    marginLeft: '10px'
  },
  radioButtons: {
    width: '300px',
    display: 'flex',
    justifyContent: 'space-around'
  },
  row: {
    display: 'flex',
    paddingLeft: '20px',
    height: '36px',
    alignItems: 'center',
    fontSize: 'inherit'
  },
  expandCell: { width: '20px' },
  numberCell: {
    width: '140px'
  },
  number: { display: 'flex', alignItems: 'center' },
  aliasBox: {
    padding: '5px',
    backgroundColor: '#92BC68',
    color: '#FFF',
    borderRadius: '5px',
    fontSize: '10px',
    marginLeft: '10px'
  },
  functionCell: {
    width: '180px'
  },
  nameCell: {
    width: '200px'
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 40px',
    whiteSpace: 'normal'
  }
};
