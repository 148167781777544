const styles = theme => ({
  root: {
    position: 'relative',
    minWidth: '400px',
    height: 'fit-content',
    margin: '5px',
    [theme.breakpoints.down(theme.breakpoints.values.sm + 40)]: {
      width: 'calc(100vw - 10px);',
      margin: 0,
      fontSize: '14px'
    }
  },
  title: {
    display: 'flex',
    height: '40px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 500,
    color: ' #595D6E',
    borderBottom: '1px solid #F2F3F8'
  },
  contentContainer: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  buttonLabel: {
    width: '160px',
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    padding: '5px 10px',
    textAlign: 'center',
    '&:hover': { filter: 'brightness(0.98)' }
  },
  icon: {
    width: '42px',
    paddingRight: '10px'
  }
});

export default styles;
