import React from 'react';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import styles from '../styles/userDetailView';
import { translate } from '../../../i18n/I18nProvider';

const UserDetailView = ({ classes, data }) => {
  return (
    <div className={classes.root}>
      <div className={clsx(classes.dataRow, classes.rowDark)}>
        <div className={clsx(classes.rowTitle, classes.fieldName)}>
          {translate('FIELD_NAME')}
        </div>
        <div className={classes.rowTitle}>{translate('VALUE')}</div>
      </div>
      {data.fields.map((field, i) => {
        return (
          <div className={clsx(classes.dataRow, classes.rowDark)} key={i}>
            <div className={classes.fieldName}>{field.name}</div>
            {field.value}
          </div>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(UserDetailView);
