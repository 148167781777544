import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import tableColumns from './tableColumns';
import { actions as reduxActions } from '../reducers';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import { translate } from '../../../i18n/I18nProvider';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';

const UsersTable = ({ columns, setColumns, data }) => {
  const [filtering, setFiltering] = useState(false);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'TestUsers-1.1'
    });
  }, [setColumns, tableDefaultColumns]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: translate('BUTTON.FILTER')
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      filtering,
      maxBodyHeight: 'calc(80vh - 75px)'
    };
  }, [filtering]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
    // eslint-disable-next-line
  }, []);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'TestUsers-1.1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.USERS"
            icon={<i className="fas fa-users"></i>}
          />
        }
        actions={actions}
        columns={columns}
        data={data}
        options={options}
        localization={localization}
        icons={customTableIcons}
        onChangeColumnHidden={onChangeColumnHidden}
      />
    </>
  );
};

const mapStatesToProps = ({ testUserSync }) => {
  return {
    data: testUserSync.data,
    columns: testUserSync.columns
  };
};

const mapDispatchToProps = {
  setColumns: reduxActions.setColumns
};

export default connect(mapStatesToProps, mapDispatchToProps)(UsersTable);
