const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    centered: {
      textAlign: 'center'
    },
    select: { marginTop: '10px' },
    lowerHeader: { textAlign: 'center', zIndex: 0 },
    tableContainer: {
      position: 'relative'
    },
    tableOwerlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    toolbarTitle: {
      overflow: 'visible',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: 'fit-content'
      }
    },
    switchCell: { padding: '0 8px' },
    actionCell: {
      display: 'flex',
      padding: '0 5px',
      '& i': {
        margin: '5px 5px',
        cursor: 'pointer'
      }
    }
  };
};

export default styles;
