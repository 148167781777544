import React, { useState } from 'react';
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  withStyles
} from '@material-ui/core';

import styles from '../styles/rangesDetails';
import Optional from '../../../utils/optional';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import NumberData from '../../../partials/numberData/NumberData';
import { translate } from '../../../i18n/I18nProvider';

const NumberDetails = ({ data, isMobile, classes }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        {false && (
          <TableCell className={classes.expandCell}>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
        )}
        <TableCell className={classes.numberCell}>
          <div className={classes.number}>
            {data.number}
            {data.function === 'USER' && data.alias === true && (
              <div className={classes.aliasBox}>Alias</div>
            )}
          </div>
        </TableCell>
        <TableCell className={classes.functionCell}>
          {Optional(data.function).or('')}
        </TableCell>
        <TableCell className={classes.nameCell}>
          {Optional(data.name).or('')}
        </TableCell>
        {isMobile && (
          <TableCell className={classes.nameCell}>
            {Optional(data.description).or('')}
          </TableCell>
        )}
        <>
          <TableCell className={classes.nameCell}>
            {Optional(findLineTranslation(data.line)).or('')}
          </TableCell>
          <TableCell className={classes.nameCell}>
            {Optional(findTypeTranslation(data.type)).or('')}
          </TableCell>
        </>
      </TableRow>
      {false && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} unmountOnExit>
              <NumberData number={data.number} getSubscriptionData={false} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default withStyles(styles)(NumberDetails);

const findLineTranslation = line => {
  const translations = {
    primary: translate('PRIMARY'),
    secondary: translate('SECONDARY')
  };

  return Optional(translations[line]).or(line);
};

const findTypeTranslation = type => {
  const translations = {
    'Office fixed': translate('NUMBERS.OFFICE_FIXED'),
    'Office mobile': translate('NUMBERS.OFFICE_MOBILE'),
    'Private fixed': translate('NUMBERS.PRIVATE_FIXED'),
    'Private mobile': translate('NUMBERS.PRIVATE_Mobile')
  };

  return Optional(translations[type]).or(type);
};
