import { call, put, select } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { OUTSIDE_API } from '../../../consts/endpoints';
import { actions as reduxActions, getters } from '../reducers';
import { actions as sagaActions, GET_QUEUES } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import urlCreator from '../../../utils/urlCreator';
import Optional from '../../../utils/optional';
import mapQueuesForStore from './mapQueuesForStore';
import { mitelRequest } from '../../../utils/mitelRequest';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import { getData } from '../../../utils/http';
import getMitelRequestProps from '../../../utils/getMitelRequestProps';
import RESPONSE_CODES from '../../../consts/responseCodes';
import groupQueuesByUser from './groupQueuesByUser';

export function getQueues({
  connectDomain,
  isAdminUser,
  isCurrentUserSelectedClientUser,
  currentUserConnectId,
  sortQueues
}) {
  return {
    type: sagaActions[GET_QUEUES],
    connectDomain,
    isAdminUser,
    isCurrentUserSelectedClientUser,
    currentUserConnectId,
    sortQueues
  };
}

export function* fetchQueues({
  connectDomain,
  isAdminUser,
  isCurrentUserSelectedClientUser,
  currentUserConnectId,
  sortQueues
}) {
  yield put(loaderReducActions.startSpinLoader());
  if (isUndefined(connectDomain)) {
    yield put(
      reduxActions.setQueues({
        queues: [],
        queuesStats: [],
        selectOptions: []
      })
    );
    yield put(loaderReducActions.stopSpinLoader());
  } else {
    const prevQueues = yield select(getters.getQueues);

    const getOnlyStats = isAdminUser && !isCurrentUserSelectedClientUser;

    const queuesToken =
      isCurrentUserSelectedClientUser && isDefined(currentUserConnectId)
        ? yield call(
            getData,
            `${OUTSIDE_API.USERS}/${currentUserConnectId}/distgroups/subscription`
          )
        : {};

    const { data: queues = [] } = !getOnlyStats
      ? yield call(mitelRequest, {
          options: { url: urlCreator(OUTSIDE_API.QUEUES, {}) }
        })
      : [];

    const { data: queuesStats } = yield call(mitelRequest, {
      options: {
        url: urlCreator(
          `${OUTSIDE_API.CLIENTS}/${connectDomain}/${OUTSIDE_API.DISTGROUP_STATS}`,
          {}
        )
      }
    });

    const newQueues = yield call(mapQueuesForStore, {
      queuesStats,
      queues,
      prevQueues,
      getOnlyStats,
      isAdminUser,
      sortQueues
    });

    const queuesGroupedByUser = yield call(groupQueuesByUser, newQueues);

    const selectOptions = yield call(mapOptions, newQueues);
    const { mitelToken, baseUrl, clientDomain, mitelUserId } = yield call(
      getMitelRequestProps
    );

    let activities = [];
    if (isDefined(mitelToken) && isDefined(mitelUserId)) {
      try {
        const { data: allActivity } = yield call(getData, {
          options: {
            url: `${baseUrl}/api/config/user/${clientDomain}/${mitelUserId}/presence/activity?t=${mitelToken}`
          },
          errorsToPass: [RESPONSE_CODES.NOT_FOUND]
        });

        activities = Optional(allActivity?.activity).or([]);
      } catch (e) {
        console.warn(e);
      }
    }

    yield put(
      reduxActions.setQueues({
        queues: newQueues,
        queuesStats: Optional(queuesStats).or([]),
        selectOptions,
        queuesToken,
        activities,
        queuesGroupedByUser
      })
    );
    yield put(loaderReducActions.stopSpinLoader());
  }
}

function* watchGetQueues() {
  yield takeLatest(sagaActions[GET_QUEUES], fetchQueues);
}

export default watchGetQueues();

function mapOptions(queues) {
  return queues.map(queue => {
    return { value: queue.id, label: queue.name };
  });
}
