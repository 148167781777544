import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_SUBSRIPTIONS } from './types';

import { getData } from '../../../utils/http';
import { doesArrayHasLength, isArray } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';

export function getAllSubsciptions({
  clientId,
  setIsLoading,
  lang,
  accountReference
}) {
  return {
    type: sagaActions[GET_SUBSRIPTIONS],
    clientId,
    setIsLoading,
    lang,
    accountReference
  };
}

export function* getSupscriptions({
  clientId,
  lang,
  setIsLoading,
  accountReference
}) {
  yield call(setIsLoading, true);
  let subscriptions = [];
  try {
    // const response = yield call(
    //   getData,
    //   `${URLS.BILLING_CLIENTS}/${clientId}/profiles`
    // );

    const response = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/products?account_reference=${accountReference}&category=Mobil Abonnementer`
    );

    if (isArray(response?.[accountReference])) {
      subscriptions = response?.[accountReference];
    }
  } catch {}

  const mappedSubscriptions = yield call(mapSubscriptionsForSelect, {
    subscriptions,
    lang
  });

  yield put(reduxActions.setMobileSubscriptions(mappedSubscriptions));
  yield call(setIsLoading, false);
}

function* watchGetSupscriptions() {
  yield takeLatest(sagaActions[GET_SUBSRIPTIONS], getSupscriptions);
}

export default watchGetSupscriptions();

export function mapSubscriptionsForSelect({ subscriptions = [], lang }) {
  if (!doesArrayHasLength(subscriptions)) {
    return subscriptions;
  }

  const selectOptions = subscriptions
    .map(item => {
      const label = Optional(item.translations?.sku?.[lang]).or(
        Optional(item.translations?.sku?.default).or('')
      );
      return {
        value: item.sku,
        label: label
      };
    })
    .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

  return [{ value: '', label: '' }, ...selectOptions];
}
