import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import React from 'react';
import { connect } from 'react-redux';

import CustomSwitch from '../../../partials/CustomSwitch';
import { actions as reduxActions } from '../reducers';
import styles from '../styles/isUserViewSwitch';
import { translate } from '../../../i18n/I18nProvider';

const IsUserViewSwitch = ({ toggleSwitch, isUsersView, classes }) => {
  const handleSwitch = e => {
    toggleSwitch(e.target.checked);
  };

  return (
    <div className={classes.root}>
      <div>{translate('GROUP_BY')}</div>
      <div className={classes.switchRow}>
        <div>{translate('GROUPS')}</div>
        <CustomSwitch checked={isUsersView} handleChange={handleSwitch} />
        <div>{translate('MENU.USERS')}</div>
      </div>
    </div>
  );
};

const mapStatesToProps = ({ groups }) => {
  return {
    isUsersView: groups.isUsersView
  };
};

const mapDispatchToProps = {
  toggleSwitch: reduxActions.updateIsUsersView
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(IsUserViewSwitch);
