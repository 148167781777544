import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import usersTableColumns from './usersTableColums';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import { actions as reduxActions } from '../reducers';
import filterCustomJobs from '../../../utils/filterCustomJobs';
import { doesArrayHasLength } from '../../../utils/isDefined';
import CustomJobsMenu from '../../../partials/customJobsMenu/CustomJobsMenu';
import { fetchGroups } from '../actions/getGroups';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';
import GroupsTableTitle from './GroupsTableTitle';
import styles from '../styles';

const UserViewGroupsTable = ({
  intl,
  isLoading,
  columns,
  setColumns,
  groupsGroupedByUsers,
  customJobs,
  classes,
  isUsersView,
  connectClientId,
  fetchGroups
}) => {
  const [filtering, setFiltering] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      fetchGroups(connectClientId);
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const tableDefaultColumns = useMemo(() => {
    return usersTableColumns(isUsersView);
  }, [isUsersView]);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Groups'
    });
  }, [setColumns, tableDefaultColumns]);

  const mappedJobs = useMemo(() => {
    return filterCustomJobs(customJobs, 'Groups');
  }, [customJobs]);

  const actions = useMemo(
    () => {
      let buttons = [
        {
          icon: () => <FilterButton />,
          onClick: () => {
            setFiltering(!filtering);
          },
          isFreeAction: true,
          tooltip: intl.formatMessage({ id: 'BUTTON.FILTER' })
        }
      ];

      if (doesArrayHasLength(mappedJobs)) {
        buttons = [
          ...buttons,
          {
            icon: () => <CustomJobsMenu customJobs={mappedJobs} />,
            onClick: () => {},
            isFreeAction: true
          }
        ];
      }

      buttons = [
        ...buttons,
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(true),
          isFreeAction: true
        }
      ];

      return buttons;
    },
    // eslint-disable-next-line
    [filtering, customJobs]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      selection: false,
      filtering
    };
  }, [filtering]);

  const localization = useMemo(() => {
    return { ...setLocalization(intl) };
    // eslint-disable-next-line
  }, [intl]);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Groups'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <MaterialTable
      components={{
        Toolbar: props => (
          <MTableToolbar
            classes={{
              title: classes.toolbarTitle
            }}
            {...props}
          />
        )
      }}
      title={<GroupsTableTitle isLoading={isLoading} />}
      icons={customTableIcons}
      actions={actions}
      columns={columns}
      data={groupsGroupedByUsers}
      options={options}
      localization={localization}
      onChangeColumnHidden={onChangeColumnHidden}
      parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
    />
  );
};

const mapStatesToProps = ({ groups, loader, auth, selectClient }) => {
  return {
    ...groups,
    isLoading: loader.isSpinVisible,
    customJobs: auth.customJobs,
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  setColumns: reduxActions.setColumns,
  fetchGroups
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(UserViewGroupsTable);
