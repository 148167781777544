import React from 'react';
import { isArray, isDefined } from '../../../utils/isDefined';
import removeWhiteSpaces from '../../../utils/removeWhiteSpaces';
import searchInArray from '../../../utils/searchInArray';
import EnabledCell from './EnabledCell';
import searchNumbers from '../../../utils/searchNumbers';
import Cell from './Cell';

const tableColumns = () => {
  const columns = [
    {
      title: 'Enabled',
      field: 'accountEnabled',
      render: rowData => <EnabledCell enabled={rowData.accountEnabled} />,
      untranslatedTitle: true,
      lookup: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      title: 'Valid',
      field: 'creatable',
      render: rowData => <EnabledCell enabled={rowData.creatable} />,
      untranslatedTitle: true,
      lookup: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      title: 'Display Name',
      field: 'displayName',
      render: rowData => (
        <Cell data={rowData.displayName} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true
    },
    {
      title: 'Mail',
      field: 'mail',
      render: rowData => (
        <Cell data={rowData.mail} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true
    },
    {
      title: 'Job title',
      field: 'jobTitle',
      render: rowData => (
        <Cell data={rowData.jobTitle} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true
    },
    {
      title: 'Mobile phone',
      field: 'mobilePhone',
      render: rowData => (
        <Cell data={rowData.mobilePhone} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true,
      customFilterAndSearch: (term, rowData) =>
        searchNumbers(rowData.mobilePhone, term)
    },
    {
      title: 'Business phones',
      field: 'businessPhones',
      untranslatedTitle: true,
      render: rowData => (
        <Cell
          data={
            isArray(rowData.businessPhones)
              ? rowData.businessPhones.join(',')
              : ''
          }
          creatable={rowData.creatable}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: isArray(rowData.businessPhones) ? rowData.businessPhones : [],
          term: removeWhiteSpaces(term),
          isValueNumber: true
        })
    },
    {
      title: 'Department',
      field: 'department',
      render: rowData => (
        <Cell data={rowData.department} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true
    },
    {
      title: 'Office location',
      field: 'officeLocation',
      untranslatedTitle: true,
      render: rowData => (
        <Cell data={rowData.officeLocation} creatable={rowData.creatable} />
      ),
      customFilterAndSearch: (term, rowData) =>
        searchNumbers(rowData.officeLocation, term)
    },
    {
      title: 'User principal name',
      field: 'userPrincipalName',
      render: rowData => (
        <Cell data={rowData.userPrincipalName} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true
    },
    {
      title: 'Country',
      field: 'country',
      render: rowData => (
        <Cell data={rowData.country} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'State',
      field: 'state',
      render: rowData => (
        <Cell data={rowData.state} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'City',
      field: 'city',
      render: rowData => (
        <Cell data={rowData.city} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Usage location',
      field: 'usageLocation',
      render: rowData => (
        <Cell data={rowData.usageLocation} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Employe Id',
      field: 'employeeId',
      render: rowData => (
        <Cell data={rowData.employeeId} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Preferred Language',
      field: 'preferredLanguage',
      render: rowData => (
        <Cell data={rowData.preferredLanguage} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Manager name',
      field: 'manager',
      render: rowData => (
        <Cell
          data={isDefined(rowData.manager) ? rowData.manager.displayName : ''}
          creatable={rowData.creatable}
        />
      ),
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Manager mail',
      field: 'manager',
      render: rowData => (
        <Cell
          data={isDefined(rowData.manager) ? rowData.manager.mail : ''}
          creatable={rowData.creatable}
        />
      ),
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Microsoft uuid',
      field: 'id',
      render: rowData => (
        <Cell data={rowData.id} creatable={rowData.creatable} />
      ),
      untranslatedTitle: true,
      hidden: true
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
};

export default tableColumns;
