const createNumbersArray = (from, to) => {
  let numbersArray = [];

  for (let i = from; i <= to; i++) {
    numbersArray = [...numbersArray, i.toString()];
  }

  return numbersArray;
};

export default createNumbersArray;
