import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import styles, { CUSTOM_STYLES } from '../styles/subscriptionStep';
import { actions as reduxActions } from '../reducers';
import InputRow from '../../../partials/dataRows/InputRow';
import CheckboxRow from '../../../partials/dataRows/CheckboxRow';
import clsx from 'clsx';
import {
  isStringDefined,
  doesArrayHasLength,
  isDefined,
  isUndefined,
  isStringEmpty
} from '../../../utils/isDefined';
import validateSchema from '../actions/validateSchema';
import { getAllSubsciptions } from '../actions/getSupscriptions';
import Optional from '../../../utils/optional';
import SelectRow from '../../../partials/dataRows/SelectRow';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import NetworkInfo from './NetworkInfo';
import mapProvidersOptions from '../../../utils/mapProvidersOptions';
import { PROVIDERS_NUMBERS } from '../../../consts/initialValues';
import MandatoryField from './MandatoryField';
import mapSubscriptionSku from '../actions/mapSubscriptionSku';
import ValidateEuicc from './ValidateEuicc';

export function SubscriptionStep({
  classes,
  resestUserWizard,
  intl,
  handleBack,
  userData,
  setUserData,
  getAllSubsciptions,
  isMobileNumberProvided,
  client,
  mobileSubscriptions,
  isNumberOur,
  isNewNumber,
  mobileNumber,
  isMovingDateProvided,
  choosenNetwork,
  lang,
  handleNext,
  account
}) {
  const [formNetwork, setFormNetwork] = useState(choosenNetwork);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      isMobileNumberProvided ||
      (isNewNumber && isUndefined(mobileSubscriptions))
    ) {
      const accountReference = account?.externalReference;
      getAllSubsciptions({
        lang,
        clientId: client,
        setIsLoading,
        accountReference
      });
    }
    // eslint-disable-next-line
  }, [client, isMobileNumberProvided, isNewNumber, account]);

  const onSubmit = useCallback(
    dialogValues => {
      handleNext();
      setUserData(dialogValues, 'thirdStep');
    },
    // eslint-disable-next-line
    [userData]
  );

  const onBackClick = useCallback(
    () => {
      const stepSize =
        !isMobileNumberProvided || isNumberOur || isNewNumber === true ? 2 : 1;
      setUserData(null, 'thirdStep');
      handleBack(stepSize);
    },
    // eslint-disable-next-line
    []
  );

  const providersOptions = useMemo(() => mapProvidersOptions(), []);

  return (
    <Formik
      initialValues={{
        ...userData.thirdStep,
        isMobileNumberProvided,
        isNewNumber,
        mobileNumber,
        isMovingDateProvided
      }}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      validationSchema={validateSchema(intl, formNetwork).subscriptionStep}
    >
      {({
        handleSubmit,
        errors,
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldError
      }) => {
        const fieldProps = {
          errors,
          values,
          handleBlur,
          handleChange
        };
        const numberInputAdornment = Optional(
          PROVIDERS_NUMBERS[values.network]
        ).or('');

        const setNetworkValue = (field, value) => {
          if (formNetwork !== value) {
            setFieldValue('mobile_sim_card_number', '');
            setFieldValue('data_sharing_sim_card_number', '');
          }

          setFormNetwork(value);
          setFieldValue(field, value);
        };

        const setHaveSimcards = (value, field) => {
          if (field === 'have_mobile_sim_card') {
            setFieldValue('mobile_sim_card_number', '');
          }

          if (field === 'have_data_sharing_sim_card') {
            setFieldValue('data_sharing_sim_card_number', '');
          }

          if (field === 'data_sharing_card') {
            setFieldValue('data_sharing_sim_card_number', '');
            setFieldValue('have_data_sharing_sim_card', false);
          }
          return setFieldValue(field, value.target.checked);
        };

        const setSubscriptionValue = (field, value) => {
          const subscriptionNetwork = mapSubscriptionSku(value);

          if (isDefined(subscriptionNetwork)) {
            setFormNetwork(subscriptionNetwork);
            setFieldValue('network', subscriptionNetwork);
            setFieldValue('isDisabledNetworkSelect', true);
          } else {
            setFormNetwork('');
            setFieldValue('network', '');
            setFieldValue('isDisabledNetworkSelect', false);
          }

          if (formNetwork !== subscriptionNetwork) {
            setFieldValue('mobile_sim_card_number', '');
            setFieldValue('data_sharing_sim_card_number', '');
          }

          setFieldValue(field, value);
        };

        const onChangeIcc = (field, event) => {
          setFieldValue(field, event.target.value);
          setFieldError(field, '');
        };

        if (values.have_mobile_sim_card || values.have_data_sharing_sim_card) {
          values.shipping_address = '';
        }

        const networkOptions =
          values.mobile_subscription.indexOf('-W-') !== -1
            ? providersOptions.filter(option => option.value === '3 NET')
            : providersOptions.filter(option => option.value !== '3 NET');

        return (
          <Form onSubmit={handleSubmit} className={classes.root}>
            {(isMobileNumberProvided || isNewNumber) && (
              <div className={classes.sectionContainer}>
                {isMobileNumberProvided && (
                  <InputRow
                    name="mobileNumber"
                    label="REQUEST_NEW_USER.MOBILE"
                    disabled={true}
                    {...fieldProps}
                  />
                )}
                <div className={classes.rowContainer}>
                  {doesArrayHasLength(mobileSubscriptions) || isLoading ? (
                    <SelectRow
                      name="mobile_subscription"
                      label="REQUEST_NEW_USER.MOBILE_SUBSCRIPTION"
                      isEditModal={false}
                      options={mobileSubscriptions}
                      setFieldValue={(field, value) =>
                        setSubscriptionValue(field, value)
                      }
                      isLoading={isLoading}
                      isRequired={true}
                      {...fieldProps}
                    />
                  ) : (
                    <InputRow
                      name="mobile_subscription"
                      label="REQUEST_NEW_USER.MOBILE_SUBSCRIPTION"
                      isRequired={true}
                      {...fieldProps}
                    />
                  )}
                  <div className={classes.simCardContainer}>
                    <CheckboxRow
                      label="ACTIVATE_ESIM"
                      name="activate_esim"
                      disabled={
                        isStringEmpty(values.network) ||
                        values.have_mobile_sim_card === true
                      }
                      customStyles={CUSTOM_STYLES.simCardCheckbox}
                      customCheckFunction={value =>
                        setHaveSimcards(value, 'activate_esim')
                      }
                      {...fieldProps}
                    />
                    <CheckboxRow
                      label="REQUEST_NEW_USER.HAVE_SIMCARD"
                      name="have_mobile_sim_card"
                      disabled={
                        isStringEmpty(values.network) ||
                        values.activate_esim === true
                      }
                      customStyles={CUSTOM_STYLES.simCardCheckbox}
                      customCheckFunction={value =>
                        setHaveSimcards(value, 'have_mobile_sim_card')
                      }
                      {...fieldProps}
                    />

                    {values.have_mobile_sim_card && (
                      <>
                        <InputRow
                          name="mobile_sim_card_number"
                          label="REQUEST_NEW_USER.SIM_NUMBER"
                          inputAdornmentText={numberInputAdornment}
                          isRequired={true}
                          disabled={isStringEmpty(values.network)}
                          customOnChange={event =>
                            onChangeIcc('mobile_sim_card_number', event)
                          }
                          {...fieldProps}
                        />
                        <ValidateEuicc
                          name="mobile_sim_card_number"
                          setFieldValue={setFieldValue}
                          disabled={isStringEmpty(values.network)}
                          shouldReset={!values.have_mobile_sim_card}
                          network={values.network}
                          {...fieldProps}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <SelectRow
                    isDisabled={isLoading || values.isDisabledNetworkSelect}
                    name="network"
                    label="TABLE.NETWORK"
                    isEditModal={false}
                    options={networkOptions}
                    setFieldValue={(field, value) =>
                      setNetworkValue(field, value)
                    }
                    infoIcon={true}
                    infoIconTooltipIntlKey={'TOOLTIP.CLICK_FOR_INFOTOOLTIP'}
                    isInfoDialog={true}
                    infoDialogTextComponent={<NetworkInfo />}
                    isRequired={true}
                    {...fieldProps}
                  />
                </div>
                <div
                  className={clsx(classes.rowContainer, classes.sharingCard)}
                >
                  <CheckboxRow
                    label="REQUEST_NEW_USER.DATA_CARD"
                    name="data_sharing_card"
                    customStyles={CUSTOM_STYLES.checkbox}
                    disabled={
                      values.network === '3 NET' ||
                      isStringEmpty(values.network)
                    }
                    customCheckFunction={value =>
                      setHaveSimcards(value, 'data_sharing_card')
                    }
                    {...fieldProps}
                  />
                  {values.data_sharing_card && (
                    <div className={classes.simCardContainer}>
                      <CheckboxRow
                        label="ACTIVATE_ESIM"
                        name="data_sharing_card_activate_esim"
                        disabled={
                          isStringEmpty(values.network) ||
                          values.have_data_sharing_sim_card === true
                        }
                        customStyles={CUSTOM_STYLES.simCardCheckbox}
                        customCheckFunction={value =>
                          setHaveSimcards(
                            value,
                            'data_sharing_card_activate_esim'
                          )
                        }
                        {...fieldProps}
                      />
                      <CheckboxRow
                        label="REQUEST_NEW_USER.HAVE_SIMCARD"
                        name="have_data_sharing_sim_card"
                        disabled={
                          isStringEmpty(values.network) ||
                          values.data_sharing_card_activate_esim === true
                        }
                        customStyles={CUSTOM_STYLES.simCardCheckbox}
                        customCheckFunction={value =>
                          setHaveSimcards(value, 'have_data_sharing_sim_card')
                        }
                        {...fieldProps}
                      />
                      {values.have_data_sharing_sim_card && (
                        <>
                          <InputRow
                            label="REQUEST_NEW_USER.SIM_NUMBER"
                            name="data_sharing_sim_card_number"
                            inputAdornmentText={numberInputAdornment}
                            isRequired={true}
                            disabled={isStringEmpty(values.network)}
                            customOnChange={event =>
                              onChangeIcc('data_sharing_sim_card_number', event)
                            }
                            {...fieldProps}
                          />
                          <ValidateEuicc
                            name="data_sharing_sim_card_number"
                            setFieldValue={setFieldValue}
                            disabled={isStringEmpty(values.network)}
                            shouldReset={!values.have_data_sharing_sim_card}
                            network={values.network}
                            {...fieldProps}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <MandatoryField />
            <div className={classes.footer}>
              <PrimaryButton onClick={onBackClick}>
                {intl.formatMessage({
                  id: 'BUTTON.BACK'
                })}
              </PrimaryButton>
              <PrimaryButton onClick={handleSubmit} disabled={isLoading}>
                {intl.formatMessage({
                  id: 'BUTTON.NEXT'
                })}
              </PrimaryButton>
              <SecondaryButton onClick={resestUserWizard}>
                {intl.formatMessage({
                  id: 'BUTTON.CANCEL'
                })}
              </SecondaryButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStatesToProps = ({
  requestUserWizard: { userData, mobileSubscriptions, checkNumberData },
  selectClient,
  auth
}) => {
  const { firstStep, secondStep, thirdStep } = userData;
  const isMovingDateProvided =
    isDefined(secondStep.move_number_date) ||
    secondStep.is_old_subscripion_date;
  const isNumberOur = Optional(checkNumberData.our)
    .map(our => our)
    .or(false);
  const mobileNumber = firstStep.mobile_number;
  const isMobileNumberProvided = isStringDefined(firstStep.mobile_number);
  const isNewNumber = firstStep.isNewNumber;
  const client = Optional(selectClient.selectedClient.id).or(
    auth.defaultClient.id
  );
  const choosenNetwork = thirdStep.network;
  const account = firstStep.account;
  return {
    userData,
    isMobileNumberProvided,
    isNumberOur,
    isNewNumber,
    client,
    mobileNumber,
    mobileSubscriptions,
    isMovingDateProvided,
    choosenNetwork,
    lang: auth.lang,
    account
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  getAllSubsciptions
};

export default flowRight(
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SubscriptionStep);
