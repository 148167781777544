import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { OUTSIDE_API } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_SCHEDULE } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { postData, updateData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import showSnackbar from '../../../utils/showSnackbar';
import mapScheduleForUpdate from './mapScheduleForUpdate';
import { getSchedules } from '../../../pages/schedules/actions/getSchedules';
import { translate } from '../../../i18n/I18nProvider';

export function updateOnSubmit({
  schedule,
  number,
  connectClientId,
  isEdit,
  initialName
}) {
  return {
    type: sagaActions[UPDATE_SCHEDULE],
    schedule,
    number,
    connectClientId,
    isEdit,
    initialName
  };
}

export function* updateSchedule({
  schedule,
  number,
  connectClientId,
  isEdit,
  initialName
}) {
  const mappedData = mapScheduleForUpdate({
    schedule
  });

  const response = yield call(isEdit ? updateData : postData, {
    url: isEdit
      ? `${OUTSIDE_API.CLIENTS}/${connectClientId}/schedules/${number}?schedule_name=${initialName}`
      : `${OUTSIDE_API.CLIENTS}/${connectClientId}/schedules/${number}`,
    data: mappedData
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      text: `${translate('MESSAGE.UPDATED.SCHEDULE', {
        name: schedule.name
      })}`,
      messageType: 'success',
      time: 3000
    });
  }

  if (response.status === RESPONSE_CODES.CREATED) {
    yield call(showSnackbar, {
      text: `${translate('MESSAGE.CREATED.SCHEDULE', {
        name: schedule.name
      })}`,
      messageType: 'success'
    });
  }

  yield put(reduxActions.resetSchedule());
  yield put(getSchedules(connectClientId));
}

function* watchUpdateSchedule() {
  yield takeLatest(sagaActions[UPDATE_SCHEDULE], updateSchedule);
}

export default watchUpdateSchedule();
