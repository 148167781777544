import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';
import orderBy from 'lodash.orderby';
import uniqBy from 'lodash.uniqby';

import { JSONAPI_URLS, OUTSIDE_API } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_USERS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import { doesArrayHasLength, isUndefined } from '../../../utils/isDefined';
import getFullTableData from '../../../utils/getFullTableData';
import { translate } from '../../../i18n/I18nProvider';
import Optional from '../../../utils/optional';

export function getUsers(connectClientId, clientId) {
  return {
    type: sagaActions[GET_USERS],
    connectClientId,
    clientId
  };
}

export function* fetchUsers({ connectClientId, clientId }) {
  const QUERY_PARAMS = {
    sort: 'name',
    include: ['roles', 'sim_cards'],
    fields: { roles: 'name' },
    filter: { client_id: { eq: clientId } }
  };

  if (isUndefined(connectClientId)) {
    yield put(reduxActions.setUsers([]));
  } else {
    yield put(loaderReducActions.startSpinLoader());

    let mappedUsers = [];
    let dirFields = [];
    let filtersOptions = {};
    try {
      const telephonyUsers = yield call(
        getData,
        `${OUTSIDE_API.CLIENTS}/${connectClientId}/users`
      );

      const users = yield call(
        getFullTableData,
        JSONAPI_URLS.USERS,
        QUERY_PARAMS
      );

      mappedUsers = yield call(mapUsers, { telephonyUsers, users });

      filtersOptions = yield call(mapTableSelectOptions, mappedUsers);

      if (doesArrayHasLength(mappedUsers)) {
        dirFields = yield call(mapDirFields, { mappedUsers });
      }
    } catch {
    } finally {
      yield put(reduxActions.setUsers(mappedUsers, dirFields, filtersOptions));
      yield put(loaderReducActions.stopSpinLoader());
    }
  }
}

function* watchGetTelephonyUsers() {
  yield takeLatest(sagaActions[GET_USERS], fetchUsers);
}

export default watchGetTelephonyUsers();

const fieldsIdArray = [
  'field1',
  'field2',
  'field3',
  'field4',
  'field5',
  'field6'
];

function mapUsers({ telephonyUsers = [], users = [] }) {
  return telephonyUsers.map(telephonyUser => {
    const foundedUser = users.find(
      user => user.connect30_user_id === telephonyUser.user_id
    );

    const licenseType = findLicenseType(telephonyUser);

    if (isUndefined(foundedUser)) {
      return telephonyUser;
    }

    const {
      id,
      locked,
      locked_at,
      username,
      created_at,
      updated_at,
      client_id,
      roles,
      sim_cards
    } = foundedUser;

    const simCards = sim_cards.filter(card => card.active === true);

    const mappedFields = mapFields(telephonyUser?.fields);

    const filteredFields = telephonyUser?.fields.filter(
      field => !fieldsIdArray.includes(field?.id)
    );

    return {
      ...telephonyUser,
      id,
      locked,
      locked_at,
      username,
      created_at,
      updated_at,
      client_id,
      roles,
      licenseType,
      simCards,
      dirFields: Optional(telephonyUser?.fields).or([]),
      fields: filteredFields,
      ...mappedFields
    };
  });
}

function mapDirFields({ mappedUsers }) {
  const clientDirFields = mappedUsers[0]?.dirFields;

  return orderBy(clientDirFields, ['id']).map(field => {
    return { ...field, value: '' };
  });
}

function mapTableSelectOptions(data) {
  const department = createSelectOptions({ data, key: 'department' });
  const adminRole = createSelectOptions({ data, key: 'admin_role' });
  const userRole = createSelectOptions({ data, key: 'user_role' });
  const licenseType = {
    UC: translate('USER.LICENSE_UC'),
    LU: translate('USER.LIGHT_USER'),
    BL: translate('USER.BASIC_USER')
  };

  return { department, adminRole, userRole, licenseType };
}

function createSelectOptions({ data, key }) {
  return orderBy(uniqBy(data, key), key).reduce((obj, el) => {
    const value = el[key];
    if (isUndefined(value)) {
      return { ...obj, null: '-' };
    }
    return { ...obj, [value]: value };
  }, {});
}

const findLicenseType = data => {
  if (data.license_type === 'UC') {
    return data.license_type;
  } else {
    if (!data.softphone && !data.mobile_app) {
      return 'LU';
    } else {
      return 'BL';
    }
  }
};

const mapFields = (fields = []) => {
  return fields.reduce((obj, field) => {
    if (!fieldsIdArray.includes(field.id)) {
      return obj;
    }
    return {
      ...obj,
      [field.id]: {
        name: field.name,
        value: field.value
      }
    };
  }, {});
};
