import { AbilityBuilder } from '@casl/ability';
import { isArray, isDefined, isUndefined } from './isDefined';

const defineRulesFor = (auth = {}) => {
  const { user } = auth;

  const userRules = user?.rules;
  const { can, rules } = new AbilityBuilder();

  if (isDefined(userRules)) {
    userRules.forEach(rule => {
      if (isUndefined(rule.conditions)) {
        if (isArray(rule.attributes)) {
          can(rule.actions[0], rule.subject[0], rule.attributes);
        } else {
          can(rule.actions[0], rule.subject[0]);
        }
      } else {
        if (isArray(rule.attributes)) {
          can(
            rule.actions[0],
            rule.subject[0],
            rule.attributes,
            rule.conditions
          );
        } else {
          can(rule.actions[0], rule.subject[0], rule.conditions);
        }
      }
    });
  }

  return rules;
};

export default defineRulesFor;
