import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import invoicesTableColumns from './invoicesTableColumns';
import { fetchInvoices } from '../actions/getInvoices';
import InvoiceTableTitle from './InvoiceTableTitle';
import { MuiThemeProvider } from '@material-ui/core';
import { themes } from '../styles';
import InvoiceDetails from '../../../modals/invoiceDetails/InvoiceDetails';
import Optional from '../../../utils/optional';
import InvoicesActionButtons from './InvoicesActionButtons';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import getValueFromLocation from '../../../utils/getValueFromLocation';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import { actions as reduxActions } from '../reducers';
import RefreshButton from '../../../partials/tableCustomButtons/RefreshButton';

export function InvoicesTable({
  fetchInvoices,
  clientId,
  selectedAccount,
  isLoading,
  selectOptions,
  location,
  setSelectedAccount
}) {
  const [invoices, setInvoices] = useState([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const account = getValueFromLocation(location, 'accountId');

    if (isDefined(account) && doesArrayHasLength(selectOptions)) {
      const preselectedAccount = selectOptions.find(
        option => option.value === account
      );

      if (isDefined(preselectedAccount)) {
        setSelectedAccount(preselectedAccount);
      }
    }
    // eslint-disable-next-line
  }, [location, selectOptions]);

  useEffect(() => {
    if (Object.keys(selectedAccount).length > 0) {
      fetchInvoices({
        id: selectedAccount.externalReference,
        setInvoices,
        clientId
      });
    }
    // eslint-disable-next-line
  }, [selectedAccount, clientId]);

  useEffect(() => {
    if (refresh && Object.keys(selectedAccount).length > 0) {
      fetchInvoices({
        id: selectedAccount.externalReference,
        setInvoices,
        clientId
      });
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const tableDefaultColumns = useMemo(() => {
    return invoicesTableColumns({ clientId, selectedAccount });
  }, [selectedAccount, clientId]);

  const tableOptions = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      columnsButton: false,
      padding: 'default'
    };
  }, []);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const actions = useMemo(
    () => {
      return [
        {
          icon: () => <RefreshButton tableHeader={true} />,
          onClick: () => setRefresh(true),
          isFreeAction: true
        }
      ];
    },
    // eslint-disable-next-line
    []
  );

  const dataToShow = useMemo(() => {
    if (selectedAccount.value === 'all') {
      return invoices.map(invoice => {
        const account = selectOptions.find(
          option => option.externalReference === invoice.accountReference
        );

        return { ...invoice, account: Optional(account?.shortLabel).or('') };
      });
    }

    return invoices;
  }, [invoices, selectedAccount, selectOptions]);

  const detailPanel = useCallback(
    ({ rowData }) => <InvoicesActionButtons id={rowData.invoiceNumber} />,
    []
  );

  return (
    <MuiThemeProvider theme={themes.table}>
      <MaterialTable
        title={<InvoiceTableTitle />}
        columns={tableDefaultColumns}
        data={dataToShow}
        options={tableOptions}
        localization={localization}
        actions={actions}
        isLoading={isLoading}
        detailPanel={detailPanel}
      />
      <InvoiceDetails />
    </MuiThemeProvider>
  );
}

const mapStatesToProps = ({ invoices, loader, selectClient }) => {
  return {
    ...invoices,
    isLoading: loader.isSpinVisible,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  fetchInvoices,
  setSelectedAccount: reduxActions.setSelectedAccount
};

export default flowRight(
  withRouter,
  injectIntl,
  connect(mapStatesToProps, mapDispatchToProps)
)(InvoicesTable);
