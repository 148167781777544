import { Ability } from '@casl/ability';
import store from '../store/store';
import { isStringDefined } from './isDefined';
import defineRulesFor from './defineRulesFor';

const ability = new Ability();

export default function can(action, subject, field) {
  if (isStringDefined(field)) {
    const { __caslSubjectType__, ...rest } = subject;
    return ability.can(action, __caslSubjectType__, field, rest);
  } else {
    return ability.can(action, subject);
  }
}

store.subscribe(() => {
  const auth = store.getState().auth;
  ability.update(defineRulesFor(auth));
});
