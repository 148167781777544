import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { isDefined, isObject, isUndefined } from '../../../utils/isDefined';
import RESPONSE_CODES from '../../../consts/responseCodes';
import Optional from '../../../utils/optional';
import store from '../../../store/store';
import { actions as reduxActions } from '../reducers';

const getSubscriptions = async (number, clientId) => {
  if (isDefined(number) && isDefined(clientId)) {
    try {
      const { data } = await getData({
        options: {
          url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/search?phoneNumber[]=${number}`
        },
        errorsToPass: [RESPONSE_CODES.NOT_FOUND]
      });

      if (!isObject(data)) {
        return void 0;
      }

      if (Object.keys(data).indexOf('error') === -1) {
        let subscription = Optional(data[number]?.[0]).or(void 0);

        if (isUndefined(subscription)) {
          return store.dispatch(reduxActions.setSubscription(void 0));
        }

        return subscription;
      }
    } catch {
      return void 0;
    }
  }
};

export default getSubscriptions;
