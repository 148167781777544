import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as sagaActions, OPEN_EDIT } from './types';
import { getData } from '../../../utils/http';
import { JSONAPI_URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';
import { setTelephonyUser } from '../../../modals/editUser/actions/setTelephonyUser';
import Optional from '../../../utils/optional';

export function openEditUser({ userId, clientConnectId, clientId }) {
  return {
    type: sagaActions[OPEN_EDIT],
    userId,
    clientConnectId,
    clientId
  };
}

export function* openEdit({ userId, clientConnectId, clientId }) {
  const queryParams = {
    filter: { connect30_user_id: userId, client_id: clientId },
    include: 'roles',
    fields: { roles: 'name' }
  };
  let user = {};
  try {
    const { data } = yield call(
      getData,
      urlCreator(JSONAPI_URLS.USERS, queryParams)
    );

    user = Optional(data[0]).or({});
  } catch {
  } finally {
    yield put(
      setTelephonyUser({
        isTelephonyUser: true,
        userId,
        clientConnectId,
        roles: Optional(user.roles).or([]),
        id: user.id,
        fromGroupsTable: true,
        fromUsersTable: false
      })
    );
  }
}

function* watchOpenEdit() {
  yield takeLatest(sagaActions[OPEN_EDIT], openEdit);
}

export default watchOpenEdit();
