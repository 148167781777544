import React from 'react';
import { withStyles } from '@material-ui/styles';
import styles from '../styles/tableTitle';
import { translate } from '../../../i18n/I18nProvider';
import { CircularProgress } from '@material-ui/core';
import IsUserViewSwitch from './IsUserViewSwitch';
import clsx from 'clsx';

const GroupsTableTitle = ({ classes, isLoading }) => {
  return (
    <div className={classes.title}>
      <div>
        <i className={clsx('fas fa-users', classes.icon)}></i>
        <span className={classes.titleText}>{translate('MENU.GROUPS')}</span>
      </div>
      {isLoading ? <CircularProgress /> : null}
      <div className={classes.actions}>
        <IsUserViewSwitch />
      </div>
    </div>
  );
};

export default withStyles(styles)(GroupsTableTitle);
