import { Field, Formik, Form } from 'formik';
import React, { useCallback, useMemo } from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

import ActionsButtons from './ActionsButtons';
import styles, { customStyles } from '../styles';
import InfoBox from './InfoBox';
import InputRow from '../../../partials/dataRows/InputRow';
import SelectRow from '../../../partials/dataRows/SelectRow';
import { doesArrayHasLength } from '../../../utils/isDefined';
import addSchedules from '../actions/addSchedules';
import translateOptionslLabels from '../../../utils/translateOptionsLabels';
import DaysElement from '../../editSchedule/components/DaysElement';
import DatesElement from '../../editSchedule/components/DatesElement';
import EndElement from '../../editSchedule/components/EndElement';
import PromptRow from '../../editSchedule/components/PromptRow';
import validateOnSubmit from '../actions/validateForm';
import SELECTS_OPTIONS from '../../../consts/selectsOptions';
import Optional from '../../../utils/optional';
import { EMPTY_SCHEDULE } from '../../../consts/initialValues';
import { actions as reduxActions } from '../reducers';
import CheckboxesElement from '../../editSchedule/components/CheckboxesElement';
import validateSchema from '../actions/validateSchema';

const NewDay = ({
  classes,
  addSchedules,
  prompts: promptsOptions,
  customErrors,
  schedules,
  connectClientId,
  closeDialog
}) => {
  const onSubmit = useCallback(
    async values => {
      const isValidated = await validateOnSubmit({
        values
      });

      if (isValidated) {
        addSchedules({ values, schedules, connectClientId });
      }
    },
    // eslint-disable-next-line
    [schedules, connectClientId]
  );

  const repeatOptions = useMemo(() => {
    return translateOptionslLabels(SELECTS_OPTIONS.SCHEDULES_REPEAT_OPTIONS);
  }, []);

  const typeOptions = useMemo(() => {
    return translateOptionslLabels(SELECTS_OPTIONS.SCHEDULES_TYPE_OPTIONS);
  }, []);
  return (
    <Formik
      initialValues={{ ...EMPTY_SCHEDULE }}
      enableReinitialize
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validateSchema}
    >
      {({
        handleSubmit,
        dirty,
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        errors
      }) => {
        const fieldProps = {
          values,
          handleBlur,
          handleChange,
          setFieldValue
        };

        const mappedErrors = { ...customErrors, ...errors };
        return (
          <>
            {Object.keys(mappedErrors).length > 0 && <InfoBox />}
            <Form className={classes.content} onSubmit={handleSubmit}>
              <>
                <div className={classes.nameRow}>
                  <InputRow
                    label="NAME"
                    name="name"
                    values={values}
                    errors={mappedErrors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    customStyles={customStyles.input.name}
                    disabled={doesArrayHasLength(values.vacations)}
                  />
                </div>
                <SelectRow
                  name="recurrenceType"
                  label="SCHEDULE_REPEAT"
                  options={repeatOptions}
                  isDisabled={doesArrayHasLength(values.vacations)}
                  {...fieldProps}
                />
                {values.recurrenceType === 'WEEKLY' && (
                  <Field component={DaysElement} />
                )}
                {(values.recurrenceType === 'DAILY' ||
                  values.recurrenceType === 'WEEKLY') && (
                  <Field component={CheckboxesElement} />
                )}

                <Field
                  component={DatesElement}
                  systemDateSelected={doesArrayHasLength(values.vacations)}
                />

                {(values.recurrenceType === 'MONTHLY' ||
                  values.recurrenceType === 'YEARLY') && (
                  <Field
                    component={EndElement}
                    systemDateSelected={doesArrayHasLength(values.vacations)}
                  />
                )}
              </>
              <Field component={PromptRow} promptsOptions={promptsOptions} />

              <div className={classes.typeContainer}>
                <SelectRow
                  name="type"
                  label="SCHEDULE_TYPE"
                  customStyles={customStyles.select.typeSelect}
                  options={typeOptions}
                  {...fieldProps}
                />
                {values.type === 'FORWARD' && (
                  <div>
                    <TextField
                      name="forwardNumber"
                      value={Optional(values.forwardNumber).or('')}
                      margin="dense"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.forwardNumber)}
                      inputProps={{
                        autoComplete: 'off',
                        'data-lpignore': true
                      }}
                    />
                    <div className={classes.errorMessage}>
                      {mappedErrors.forwardNumber}
                    </div>
                  </div>
                )}
              </div>
              <footer className={classes.footer}>
                <ActionsButtons
                  onCloseClick={closeDialog}
                  handleSubmit={handleSubmit}
                  dirty={dirty}
                  errors={mappedErrors}
                />
              </footer>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStatesToProps = ({ addingSchedules, selectClient }) => {
  return {
    ...addingSchedules,
    connectClientId: selectClient.selectedClient.connect30_domain
  };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeDialog,
  addSchedules
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(NewDay);
