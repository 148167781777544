const styles = theme => {
  return {
    root: {
      width: '320px',
      borderRadius: '0',
      height: '135px',
      fontSize: '20px',
      margin: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        width: '270px'
      }
    },
    button: {
      height: '65px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': { filter: 'brightness(0.98)' }
    },
    smallButtonsContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    smallButton: {
      height: '65px',
      width: '48%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': { filter: 'brightness(0.98)' }
    },
    label: {
      fontSize: '16px',
      marginRight: '10px',
      fontWeight: 'bold',
      textAlign: 'end'
    },
    icon: {
      width: '64px',
      paddingLeft: '20px'
    },
    smallIcon: {
      width: '48px',
      paddingLeft: '20px'
    }
  };
};

export default styles;
