import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_GROUPS } from './types';
import { actions as loaderReducActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import { isArrayEmpty, isUndefined } from '../../../utils/isDefined';
import { OUTSIDE_API } from '../../../consts/endpoints';

export function fetchGroups(connectClientId) {
  return {
    type: sagaActions[GET_GROUPS],
    connectClientId
  };
}

export function* getGroups({ connectClientId }) {
  if (isUndefined(connectClientId)) {
    yield put(reduxActions.setGroups([]));
  } else {
    yield put(loaderReducActions.startSpinLoader());

    let groups = [];
    let groupsGroupedByUsers = [];
    try {
      const response = yield call(
        getData,
        `${OUTSIDE_API.CLIENTS}/${connectClientId}/user_groups`
      );

      groups = response.map(el => {
        return { ...el, id: el.name };
      });

      const usersResponse = yield call(
        getData,
        `${OUTSIDE_API.CLIENTS}/${connectClientId}/users`
      );

      groupsGroupedByUsers = mapUsers(groups, usersResponse);
    } catch {
    } finally {
      yield put(reduxActions.setGroups(groups, groupsGroupedByUsers));
      yield put(loaderReducActions.stopSpinLoader());
    }
  }
}

function* watchGetGroups() {
  yield takeLatest(sagaActions[GET_GROUPS], getGroups);
}

export default watchGetGroups();

const mapUsers = (groups, users) => {
  if (isArrayEmpty(users)) {
    return [];
  }

  const mappedUsers = users.map(user => {
    const userGroups = user.groups;
    if (isUndefined(userGroups)) {
      return user;
    }

    const mappedUserGroups = userGroups.map(userGroup => {
      const foundedGroup = groups.find(group => group.name === userGroup);

      if (isUndefined(foundedGroup)) {
        return userGroup;
      }

      return foundedGroup;
    });

    return { ...user, groups: mappedUserGroups };
  });

  return mappedUsers.reduce((arr, user, i) => {
    const mappedGroups = user.groups.map(group => {
      return { ...group, parentId: i };
    });
    return [...arr, { ...user, id: i }, ...mappedGroups];
  }, []);
};
