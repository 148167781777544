import React from 'react';
import { call, put } from 'redux-saga/effects';
import { takeLatest } from '../../../utils/reduxSaga';

import { actions as reduxActions } from '../reducers';
import { actions as sagaActions, GET_CONNECTIONS } from './types';
import { actions as loaderReduxActions } from '../../../partials/loader/reducers';
import { getData } from '../../../utils/http';
import { URLS } from '../../../consts/endpoints';
import { isArray, isUndefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import Optional from '../../../utils/optional';
import orderBy from 'lodash.orderby';

export function getConnections({ clientId }) {
  return {
    type: sagaActions[GET_CONNECTIONS],
    clientId
  };
}

export function* fetchConnections({ clientId }) {
  yield put(loaderReduxActions.startSpinLoader());

  let connections = [],
    accounts = [],
    selectOptions = [],
    selectedAccount = {};

  try {
    const data = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/accounts`
    );

    accounts = data;
    selectOptions = yield call(createSelectOptions, data);

    selectedAccount = Optional(selectOptions[0]).or({});
  } catch {}

  try {
    const data = yield call(
      getData,
      `${URLS.QUOTE_CLIENTS}/${clientId}/connections`
    );

    if (isArray(data)) {
      connections = data
        .map(el => el.subscription)
        .map(el => {
          const account = accounts.find(
            account => account.id === el.parentAccountId
          );

          if (isUndefined(account)) {
            return el;
          }

          const accountName = `${Optional(account.name).or('')} - ${Optional(
            account.accountNo
          ).or('')}`;

          return { ...el, accountName };
        });
    }
  } catch {}

  yield put(reduxActions.setAccounts(accounts, selectOptions, selectedAccount));
  yield put(reduxActions.setConnections(connections));
  yield put(loaderReduxActions.stopSpinLoader());
}

function* watchGetConnections() {
  yield takeLatest(sagaActions[GET_CONNECTIONS], fetchConnections);
}

export default watchGetConnections();

function createSelectOptions(data = []) {
  const options = data.map(el => {
    const { id, name, accountNo, type } = el;
    return {
      value: id,
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginLeft: '5px' }}>{`${name} - ${accountNo}`}</div>
          {type === 'CUSTOMER' ? (
            <i className="fas fa-handshake-alt"></i>
          ) : (
            <i className="fal fa-money-bill-alt"></i>
          )}
        </div>
      ),
      shortLabel: `${name} - ${accountNo}`,
      ...el
    };
  });

  const customerAccounts = options.filter(el => el.type === 'CUSTOMER');
  const invoiceAccounts = options.filter(el => el.type === 'INVOICE');

  return [
    { value: 'ALL', label: translate('INVOICES.ALL_ACCOUNTS') },
    ...orderBy(customerAccounts, 'shortLabel'),
    ...orderBy(invoiceAccounts, 'shortLabel')
  ];
}
