import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import GroupsTable from './components/GroupsTable';
import { actions as reduxActions } from './reducers';
import UserViewGroupsTable from './components/UserViewGroupsTable';

const Groups = ({ resetGroups, isUsersView }) => {
  useEffect(() => {
    return resetGroups;
    // eslint-disable-next-line
  }, []);

  return <>{isUsersView ? <UserViewGroupsTable /> : <GroupsTable />}</>;
};

const mapStatesToProps = ({ groups }) => {
  return {
    ...groups
  };
};

const mapDispatchToProps = {
  resetGroups: reduxActions.resetGroups
};

export default connect(mapStatesToProps, mapDispatchToProps)(Groups);
