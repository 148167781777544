import { Ability } from '@casl/ability';
import { URLS } from '../consts/endpoints';
import { getData } from './http';
import { isDefined } from './isDefined';
import Optional from './optional';
import urlCreator from './urlCreator';
import createSubject from './createSubject';
import defineRulesFor from './defineRulesFor';

const checkIfIoTShouldBeShown = async (clientId, rules) => {
  let shouldMakeRequest = true;

  if (isDefined(rules)) {
    shouldMakeRequest = checkSubsPermission({ clientId, rules });
  }

  if (shouldMakeRequest) {
    const queryParams = {
      page: { size: 1 },
      product_category: 'IoT',
      execute_fetch_events: false,
      return_projections: false
    };
    const response = await getData({
      options: {
        url: urlCreator(
          `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions`,
          queryParams
        )
      }
    });

    const count = Number(Optional(response?.headers?.['x-total-count']).or(0));

    return count > 0 ? count : false;
  }
};

export default checkIfIoTShouldBeShown;

const checkSubsPermission = ({ clientId, rules }) => {
  const ability = new Ability();

  ability.update(defineRulesFor({ user: { rules } }));

  const subject = createSubject('Quote::Subscription', {
    client_id: Number(clientId)
  });

  return ability.can('read', subject);
};
