import { URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { isArray } from '../../../utils/isDefined';
import orderBy from 'lodash.orderby';

const getAccounts = clientId => {
  return async dispatch => {
    const data = await getData(`${URLS.QUOTE_CLIENTS}/${clientId}/accounts`);

    if (isArray(data)) {
      dispatch(reduxActions.setAccounts(mapData(data)));
    }
  };
};

export default getAccounts;

const mapData = (data = []) => {
  const mappedData = data.map(el => {
    const { name, accountNo } = el;
    return { ...el, label: `${name} - ${accountNo}` };
  });

  const customerAccounts = mappedData.filter(el => el.type === 'CUSTOMER');
  const invoiceAccounts = mappedData.filter(el => el.type === 'INVOICE');

  return [
    ...orderBy(customerAccounts, 'label'),
    ...orderBy(invoiceAccounts, 'label')
  ];
};
