export default {
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  sectionContainer: {
    margin: '10px 0 20px 0'
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  sharingCard: {
    justifyContent: 'space-between',
    height: '235px'
  },
  cardInfoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  cardInfo: {
    display: 'flex',
    fontSize: '12px',
    fontStyle: 'italic',
    maxWidth: '400px',
    padding: '10px'
  },
  starIcon: { fontSize: '8px', paddingBottom: '5px' },
  simCardContainer: { height: '90px', width: '500px' },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #EBEDF2',
    padding: '10px',
    '& button': {
      margin: '10px'
    }
  }
};

export const CUSTOM_STYLES = {
  notes: {
    root: { width: '400px' },
    textfield: { width: '270px' },
    label: { width: '50px' }
  },
  simCardCheckbox: {
    root: { width: '400px', margin: '0 30px 0 0' },
    label: {
      width: '250px'
    }
  },
  checkbox: {
    root: {
      width: '400px',
      display: 'flex',
      alignItems: 'flex-start',
      paddingLeft: '30px'
    }
  }
};
